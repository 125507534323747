<template>
  <div>
    <!-- <div style="display: none">
      <form
        id="form1"
        @submit="Submit()"
        action=" ApiUrl "
        runat="server"
        method="post"
      >
        <input type="hidden" id="ApiURL" :value="ApiUrl" />
        <div>
          <input
            ID="ssl_merchant_id"
            name="ssl_merchant_id"
            :value="MerchantID"
            runat="server"
          />
          <input
            ID="ssl_user_id"
            name="ssl_user_id"
            :value="UserID"
            runat="server"
          />
          <input ID="ssl_pin" name="ssl_pin" :value="PinID" runat="server" />
          <input
            ID="ssl_transaction_type"
            name="ssl_transaction_type"
            :value="TransactionType"
            runat="server"
          />
          <input
            ID="ssl_show_form"
            name="ssl_show_form"
            runat="server"
            :value="false"
          />

          <input
            ID="ssl_receipt_apprvl_method"
            name="ssl_receipt_apprvl_method"
            runat="server"
            :value="REDG"
          />
          <input
            ID="ssl_receipt_apprvl_get_url"
            name="ssl_receipt_apprvl_get_url"
            runat="server"
            :value="ResponseOnApproval"
          />
          <input
            ID="ssl_receipt_decl_method"
            name="ssl_receipt_decl_method"
            runat="server"
            :value="TransactionMethod"
          />
          <input
            ID="ssl_receipt_decl_get_url"
            name="ssl_receipt_decl_get_url"
            runat="server"
            :value="ResponseOnDecline"
          />
          <input
            ID="ssl_error_url"
            name="ssl_error_url"
            runat="server"
            :value="ResponseOnError"
          />
          <input
            ID="ssl_invoice_number"
            name="ssl_invoice_number"
            :value="InvoiceID"
            runat="server"
          />
          <input
            ID="ssl_card_present"
            name="ssl_card_present"
            runat="server"
            :value="N"
          />

          <input
            ID="ssl_cvv2cvc2_indicator"
            name="ssl_cvv2cvc2_indicator"
            runat="server"
            :value="1"
          />

          <table>
            <tr>
              <td>Api Url</td>
              <td>
                <input :value="ApiUrl" runat="server" ReadOnly="false" />
              </td>
            </tr>
            <tr>
              <td>Numero de tarjeta:</td>
              <td>
                <input
                  ID="ssl_card_number"
                  name="ssl_card_number"
                  :value="CardNumber"
                  runat="server"
                  ReadOnly="false"
                />
              </td>
            </tr>

            <tr>
              <td>Fecha de expiracion (MMYY):</td>
              <td>
                <input
                  ID="ssl_exp_date"
                  name="ssl_exp_date"
                  :value="ExpirationDate"
                  runat="server"
                />
              </td>
            </tr>

            <tr>
              <td class="auto-style1">CVV:</td>
              <td class="auto-style1">
                <input
                  ID="ssl_cvv2cvc2"
                  name="ssl_cvv2cvc2"
                  :value="CardSecurityCode"
                  runat="server"
                />
              </td>
            </tr>

            <tr>
              <td>Amount:</td>
              <td>
                <input
                  ID="ssl_amount"
                  name="ssl_amount"
                  runat="server"
                  :value="Amount"
                  readonly
                />
              </td>
            </tr>

            <tr>
              <td>Tax:</td>
              <td>
                <input
                  ID="ssl_salestax"
                  name="ssl_salestax"
                  :value="Tax"
                  runat="server"
                  readonly
                />
              </td>
            </tr>
          </table>
        </div>
        <input id="Submit" type="submit" value="submit" />
      </form>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ApiUrl: "",
      ssl_merchant_id: "",
      ssl_user_id: "",
      ssl_pin: "",
      ssl_transaction_type: "",
      ssl_show_form: false,
      ssl_receipt_apprvl_method: "REDG",
      ssl_receipt_apprvl_get_url: "",
      ssl_receipt_decl_method: "",
      ssl_receipt_decl_get_url: "",
      ssl_error_url: "",
      ssl_invoice_number: "",
      ssl_card_present: "N",
      ssl_cvv2cvc2_indicator: "1",
      ssl_card_number: "",
      ssl_exp_date: "",
      ssl_cvv2cvc2: "",
      ssl_amount: "",
      ssl_salestax: "",
    };
  },
  created() {
    setTimeout(() => {
      this.Submit();
    }, 1000);
  },
  methods: {
    Submit() {
      axios
        .post(
          this.$store.state.creditpayment.ApiUrl,
          this.$store.state.creditpayment
        )
        .then((res) => {
          console.log(res);
        }).catch(e => {
          this.$router.push("/")
        }) 
    },
  },
};
</script>

<style>
</style>